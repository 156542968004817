import { createSlice  } from "@reduxjs/toolkit";
import { Services } from "../../../services";


const faqSlice = createSlice({
    name: 'faqSlice',
    initialState: {
        faqList:  { data: [], limit: 0, page: 0, total: 0 },
        faqCategories: {},
        privacyPolicy: [],
        purchasePolicy: [],
        deliveryPolicy: [],
        creatorTerms: [],
        leaderTerms: [],
        partnerTerms: [],
        providerTerms: [],
        serviceTerms: [],
        vendorTerms: [],
    },
    reducers: {
        addfaqList: (state, {payload}) => {
            state.faqList = payload;
        },
        addfaqCategories: (state, {payload}) => {
            state.faqCategories = payload;
        },
        addPrivacyPolicy: (state, {payload}) => {
            state.privacyPolicy = payload;
        },
        addPurchasePolicy: (state, {payload}) => {
            state.purchasePolicy = payload;
        },
        addDeliveryPolicy: (state, {payload}) => {
            state.deliveryPolicy = payload;
        },
        addCreatorTerms: (state, {payload}) => {
            state.creatorTerms = payload;
        },
        addLeaderTerms: (state, {payload}) => {
            state.leaderTerms = payload;
        },
        addPartnerTerms: (state, {payload}) => {
            state.partnerTerms = payload;
        },
        addProviderTerms: (state, {payload}) => {
            state.providerTerms = payload;
        },
        addServiceTerms: (state, {payload}) => {
            state.serviceTerms = payload;
        },
        addVendorTerms: (state, {payload}) => {
            state.vendorTerms = payload;
        }
    }
});


export const { addfaqList , addfaqCategories, addPrivacyPolicy, addPurchasePolicy, addDeliveryPolicy,
addCreatorTerms, addLeaderTerms, addPartnerTerms, addProviderTerms, addServiceTerms, addVendorTerms} = faqSlice.actions;


export const fetchAllFAQs = (payload) => dispatcher => {
    
    return Services.fetchAllFAQs(payload).then(   
            response => {
                // console.log(response.data);
                    dispatcher(addfaqList({
                        data: response.data.object,
                        total: response.data.total,
                        limit: response.data.limit,
                        page: response.data.page,
                      }))
                return Promise.resolve()
            }
    ).catch(error => {
        if(error.response){
            return Promise.reject(error.response.data);
        }
    })
}

export const fetchfaqCategories = (payload) => dispatcher => {
    return Services.fetchfaqCategories(payload).then(
        response => {
            dispatcher(addfaqCategories(response.data));
            return Promise.resolve(response.data.object)
        }
    ).catch(error => {
        if(error.response){
            return Promise.reject(error.response.data);
        }
    })
}

export const fetchPrivacyPolicies = (payload) => dispatcher => {
    return Services.fetchPolicies(payload).then(
        response => {
            dispatcher(addPrivacyPolicy(response.data.object));
            return Promise.resolve(response.data.object)
        }
    ).catch(error => {
        if(error.response){
            return Promise.reject(error.response.data);
        }
    })
}

export const fetchPurchasePolicies = (payload) => dispatcher => {
    return Services.fetchPolicies(payload).then(
        response => {
            dispatcher(addPurchasePolicy(response.data.object));
            return Promise.resolve(response.data.object)
        }
    ).catch(error => {
        if(error.response){
            return Promise.reject(error.response.data);
        }
    })
}

export const fetchDeliveryPolicies = (payload) => dispatcher => {
    return Services.fetchPolicies(payload).then(
        response => {
            dispatcher(addDeliveryPolicy(response.data.object));
            return Promise.resolve(response.data.object)
        }
    ).catch(error => {
        if(error.response){
            return Promise.reject(error.response.data);
        }
    })
}


export const fetchCreatorTerms = (payload) => dispatcher => {
    return Services.fetchTerms(payload).then(
        response => {
            dispatcher(addCreatorTerms(response.data.object));
            return Promise.resolve(response.data.object)
        }
    ).catch(error => {
        if(error.response){
            return Promise.reject(error.response.data);
        }
    })
}

export const fetchLeaderTerms = (payload) => dispatcher => {
    return Services.fetchTerms(payload).then(
        response => {
            dispatcher(addLeaderTerms(response.data.object));
            return Promise.resolve(response.data.object)
        }
    ).catch(error => {
        if(error.response){
            return Promise.reject(error.response.data);
        }
    })
}

export const fetchPartnerTerms = (payload) => dispatcher => {
    return Services.fetchTerms(payload).then(
        response => {
            dispatcher(addPartnerTerms(response.data.object));
            return Promise.resolve(response.data.object)
        }
    ).catch(error => {
        if(error.response){
            return Promise.reject(error.response.data);
        }
    })
}

export const fetchProviderTerms = (payload) => dispatcher => {
    return Services.fetchTerms(payload).then(
        response => {
            dispatcher(addProviderTerms(response.data.object));
            return Promise.resolve(response.data.object)
        }
    ).catch(error => {
        if(error.response){
            return Promise.reject(error.response.data);
        }
    })
}

export const fetchServiceTerms = (payload) => dispatcher => {
    return Services.fetchTerms(payload).then(
        response => {
            dispatcher(addServiceTerms(response.data.object));
            return Promise.resolve(response.data.object)
        }
    ).catch(error => {
        if(error.response){
            return Promise.reject(error.response.data);
        }
    })
}

export const fetchVendorTerms = (payload) => dispatcher => {
    return Services.fetchTerms(payload).then(
        response => {
            dispatcher(addVendorTerms(response.data.object));
            return Promise.resolve(response.data.object)
        }
    ).catch(error => {
        if(error.response){
            return Promise.reject(error.response.data);
        }
    })
}





export const selectFAQ = state => state.faqReducer;
export default faqSlice.reducer;

