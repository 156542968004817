import React, { useState, useEffect } from "react";
import { Table as AntTable, Tag, Popover, Modal, Pagination, Input, Select, InputNumber, Button   } from "antd";
import { Switch, Route, useRouteMatch, useHistory } from "react-router-dom";
import { PageTitleBar } from "../../globalComponents/PageTitleBar";
import { FiMoreVertical } from "react-icons/fi";
import { selectConfig } from "./slice";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { fetchAllConfigs } from "./slice";
import { useDispatch } from "react-redux";
import { StyledModal } from "../../globalComponents/styles";
import { notificationAlert } from "../../../utils/notificationAlert";
import { themes } from "../../../globalAssets/theme";
import { MainPageScaffold } from "../../globalComponents/MainPageScaffold";
import { Services } from "../../../services";

export const Configurations  = (props) => {
  const { url, path } = useRouteMatch();
  const { configList } = useSelector(selectConfig);
  const [configProps, setConfigProps] = useState({
    configKey: "",
    configValue: ""
  });
  
  const [pageProps, setPageProps] = useState({page: 0, pageSize: 10});
  const [searchValues, setSearchValues] = useState([]);
  const [isSearching, setSearching] = useState(false);
  const dispatcher = useDispatch();

  const initialFetch = async () => {
    try {
      await dispatcher(fetchAllConfigs(pageProps));
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    initialFetch()
  }, [])


  const openEditConfigModal = (data) => () => {
    setConfigProps({
      configKey: data.configKey,
      configValue: data.configValue,
      isModalVisible: true,
    });
  };

  const handleCloseEditConfigModal = () => {
    setConfigProps({
      configKey: "",
      configValue: "",
      isModalVisible: false,
    });
  };

  const handleConfigChange = (e, key) =>{
    setConfigProps({
      ...configProps,
      configKey: key,
      configValue: e.target.value
    })
  }

  const handleConfigSelectChange = (val, key) =>{
    setConfigProps({
      ...configProps,
      configKey: key,
      configValue: val
    })
  }


  const handleConfigUpdate = async () =>{
    try{
      await Services.updateConfigs(configProps);

      setConfigProps({
        configKey: "",
        configValue: "",
        isModalVisible: false,
      });

      console.log('done')

      await dispatcher(fetchAllConfigs({page:0, pageSize: 10}));

      notificationAlert(
        "success",
        "Configuration Updated",
        `Configuration Has Been Updated Successfully`
      );
    }catch(error){
      setConfigProps({
        configKey: "",
        configValue: "",
        isModalVisible: false,
      });

      if (error.response) {
        notificationAlert(
          "error",
          "Error Occurred",
          error.response.data.message || "Please try again later"
        );
      } else {
        notificationAlert("error", "Error Occurred", "Please try again later");
      }
    }
  }

  const handlePageSizeChange = async (current, pageSize) => {
    await dispatcher(fetchAllConfigs({page: current-1, pageSize: pageSize}));
    setPageProps({page: current-1, pageSize: pageSize})

  };

  const handlePagination = async (current, pageSize) => {
    await dispatcher(fetchAllConfigs({page: current-1, pageSize: pageSize}));
    setPageProps({page: current-1, pageSize: pageSize})
  };
  
  const handleUserSearch = (event) => {
    (async () => {
      try {
        console.log(event);
        setSearching(Boolean(event.target.value))
        const response = await Services.searchUser({
          search: event.target.value,
        });
        setSearchValues(response.data.data.body);
        console.log(response.data.data.body);
      } catch (error) {
        console.log(error);
      }
    })();
  };

  return (
    <>
      <Switch>
        <Route exact path={`${path}`}>
          <MainPageScaffold onSearch={handleUserSearch} showSearch>
            <PageTitleBar hideButtons={true} title="Configurations" />
            {configList?.data.map((data) => (
              <div style={{marginTop: '20px'}}>
                  <h4>
                    {data.configKey.replace(/_/g, ' ')}
                  </h4>
                  <div 
                  style={{
                    display: "flex",
                  }}
                  >
                    <div>
                      { data.configValue !== "true" && data.configValue !== 'false' ?
                        <Input placeholder="Input Value" 
                        onChange={val => handleConfigChange(val, data.configKey)}
                        disabled
                        value={data.configValue} 
                        style={{
                          width: 300,
                        }}
                        />
                      :
                      <Select
                        value={data.configValue}
                        style={{
                          width: 120,
                        }}
                        onChange={val => handleConfigSelectChange(val, data.configKey)}
                        disabled
                        options={[
                          {
                            value: 'true',
                            label: 'True',
                          },
                          {
                            value: 'false',
                            label: 'False',
                          },
                        ]}
                      />
                      }
                    </div>
                    <div style={{marginLeft: 5}}>
                      <Button onClick={openEditConfigModal(data)}>Edit</Button>
                    </div>
                  </div>
              </div>
            ))}


          <div className="repush-btn-div">
            <div>
              <Pagination
                showSizeChanger
                onShowSizeChange={handlePageSizeChange}
                defaultCurrent={3}
                total={configList?.total}
                onChange={handlePagination}
              />
            </div>
          </div>

          <EditConfigModal 
            configKey={configProps.configKey}
            configValue={configProps.configValue}
            onOk={handleConfigUpdate}
            handleConfigChange={val => handleConfigChange(val, configProps.configKey)}
            handleConfigSelectChange={val => handleConfigSelectChange(val, configProps.configKey)}
            visible={configProps.isModalVisible}
            onCancel={handleCloseEditConfigModal}
          />
          </MainPageScaffold>
        </Route>
      </Switch>
    </>
  );
};

export const EditConfigModal = (props) => {
  return (
    <StyledModal
      width={1000}
      visible={props.visible}
      okButtonProps={{
        loading: props.loading,
        style: {
          backgroundColor: themes.primaryColor,
          border: `1px solid ${themes.primaryColor}`,
        },
      }}
      cancelButtonProps={{
        type: "danger",
      }}
      onOk={props.onOk}
      okText="Update"
      onCancel={props.onCancel}
    >  

      <div style={{marginTop: '20px'}}>
        <h4>
          {props.configKey.replace(/_/g, ' ')}
        </h4>
        <div>
          { props.configValue !== "true" && props.configValue !== 'false' ?
            <Input placeholder="Input Value" 
            onChange={props.handleConfigChange}
            value={props.configValue} 
            style={{
              width: 500,
            }}
            />
          :
          <Select
            defaultValue={props.configValue}
            style={{
              width: 500,
            }}
            onChange={props.handleConfigSelectChange}
            options={[
              {
                value: 'true',
                label: 'True',
              },
              {
                value: 'false',
                label: 'False',
              },
            ]}
          />
          }
        </div>
      </div>
      
    </StyledModal>
  );
};

const StyledAntTable = styled(AntTable)`
  & .ant-table-tbody > tr > td {
    cursor: pointer;
  }
`;
