import React, { useState, useEffect } from "react";
import { Table as AntTable, Tag, Popover, Modal, Pagination } from "antd";
import { Switch, Route, useRouteMatch, useHistory } from "react-router-dom";
import { TableTopBar } from "../../globalComponents/TableTopBar";
import { TableComponent } from "../../globalComponents/TableComponent";
import { PageTitleBar } from "../../globalComponents/PageTitleBar";
import { FiMoreVertical } from "react-icons/fi";
import { selectDocument, fetchAllDocuments } from "./slice";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { StyledModal } from "../../globalComponents/styles";
import { notificationAlert } from "../../../utils/notificationAlert";
import { themes } from "../../../globalAssets/theme";
import { MainPageScaffold } from "../../globalComponents/MainPageScaffold";
import { Services } from "../../../services";

export const Documents  = (props) => {
  const { url, path } = useRouteMatch();
  const history = useHistory();
  const { documentList } = useSelector(selectDocument);
  
  const [verifyDocProps, setVerifyDocProps] = useState({
    id: "",
    verificationStatus: "",
    isModalVisible: false,
  });

  const openVerifyDocModal = (doc) => () => {
    setVerifyDocProps((prevState) => ({
      ...prevState,
      id: doc.id,
      verificationStatus: "VERIFIED",
      isModalVisible: true,
    }));
  };

  const handleCloseVerifyDocModal = () => {
    setVerifyDocProps((prevState) => ({
      ...prevState,
      isModalVisible: false,
    }));
  };

  const handleDocumentVerification = async () =>{
    try{
      await Services.verifyDocument(verifyDocProps);

      setVerifyDocProps({
        id: "",
        verificationStatus: "",
        isModalVisible: false,
      });

      console.log('done')

      await dispatcher(fetchAllDocuments({page:0}));

      notificationAlert(
        "success",
        "Document Verified",
        `Document Has Been Verified Successfully`
      );
    }catch(error){
      setVerifyDocProps({
        id: "",
        verificationStatus: "",
        isModalVisible: false,
      });

      if (error.response) {
        notificationAlert(
          "error",
          "Error Occurred",
          error.response.data.message || "Please try again later"
        );
      } else {
        notificationAlert("error", "Error Occurred", "Please try again later");
      }
    }
  }

  // const [docPageSize, setDocPageSize] = useState(10);
  // const [currentPage, setCurrentPage] = useState(0);
  const [searchValues, setSearchValues] = useState([]);
  const [isSearching, setSearching] = useState(false);
  const dispatcher = useDispatch();

  const initialFetch = async () => {
    try {
      await dispatcher(fetchAllDocuments({page: 0, limit: 10}));
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    initialFetch()
  }, [])

  // const handlePageSizeChange = async (current, limit) => {
  //   await dispatcher(fetchAllDocuments({page: current-1, pageSize: limit}));
  //   setDocPageSize(limit)

  // };

  // const handlePagination = async (current, limit) => {
  //   await dispatcher(fetchAllDocuments({page: current-1, pageSize: limit}));
  //   setCurrentPage(current-1)
  // };

  
  const columns = [
    {
      title: "Document Name",
      dataIndex: "documentName",
      key: "documentName",
    },
    {
      title: "Name",
      dataIndex: "userFullName",
      key: "userFullName",
    },
    {
      title: "Email",
      dataIndex: "userEmail",
      key: "userEmail",
    },

    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (action, alldata) => {
        const content = (
          <div>
            <p
              style={{ cursor: "pointer" }}
            >
              View Document
            </p>
            <p
              style={{ cursor: "pointer" }}
              onClick={openVerifyDocModal(alldata)}
            >
              Verify
            </p>
          </div>
        );

        return (
          <Popover trigger="click" content={content}>
            <FiMoreVertical />
          </Popover>
        );
      },
    },
  ];

  const handleUserSearch = (event) => {
    (async () => {
      try {
        console.log(event);
        setSearching(Boolean(event.target.value))
        const response = await Services.searchUser({
          search: event.target.value,
        });
        setSearchValues(response.data.data.body);
        console.log(response.data.data.body);
      } catch (error) {
        console.log(error);
      }
    })();
  };

  return (
    <>
      <Switch>
        <Route exact path={`${path}`}>
          <MainPageScaffold onSearch={handleUserSearch} showSearch>
            <PageTitleBar hideButtons={true} title="User Document Verification" />
              <StyledAntTable
                // onRow={handleRow("active")}
                columns={columns}
                dataSource={documentList.data}
              />

              {/* <div className="repush-btn-div">
              <div>
                <Pagination
                  defaultCurrent={1}
                  total={documentList?.lenght}
                  onChange={handlePagination}
                />
              </div>
            </div> */}
          </MainPageScaffold>
        </Route>
      </Switch>

      <VerifyDocModal
        id={verifyDocProps.id}
        loading={verifyDocProps.loading}
        visible={verifyDocProps.isModalVisible}
        onCancel={handleCloseVerifyDocModal}
        onOk={handleDocumentVerification}
      />
    </>
  );
};

export const VerifyDocModal = (props) => {
  return (
    <StyledModal
      visible={props.visible}
      okButtonProps={{
        loading: props.loading,
        style: {
          backgroundColor: themes.primaryColor,
          border: `1px solid ${themes.primaryColor}`,
        },
      }}
      cancelButtonProps={{
        type: "danger",
      }}
      onOk={props.onOk}
      okText="Verify"
      onCancel={props.onCancel}
    >
      <h3>Verify Document</h3>
      <p>Are you sure you want to verify this document</p>

    </StyledModal>
  );
};

const StyledAntTable = styled(AntTable)`
  & .ant-table-tbody > tr > td {
    cursor: pointer;
  }
`;
