import React, { useState, useEffect } from "react";
import { Tabs, Table as AntTable, Tag, Popover, Button, Input, Select, Collapse, Form } from "antd";
import { Switch, Route, useRouteMatch, useHistory } from "react-router-dom";
import { TableComponent } from "../../globalComponents/TableComponent";
import { PageTitleBar } from "../../globalComponents/PageTitleBar";
import { PrimaryButton } from '../../globalComponents/Buttons';
import { FiMoreVertical } from "react-icons/fi";
import { fetchfaqCategories, selectFAQ } from "./slice";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  StyledModal,
  StyledInputContainer,
} from "../../globalComponents/styles";
import {fetchAllFAQs, fetchDeliveryPolicies, fetchPrivacyPolicies, fetchPurchasePolicies, fetchCreatorTerms, fetchLeaderTerms, fetchPartnerTerms, fetchProviderTerms, fetchServiceTerms, fetchVendorTerms } from "./slice";
import { useDispatch } from "react-redux";
import { themes } from "../../../globalAssets/theme";
import { notificationAlert } from "../../../utils/notificationAlert";
import { MainPageScaffold } from "../../globalComponents/MainPageScaffold";
import { Services } from "../../../services";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const { TabPane } = Tabs;
const { Panel } = Collapse;
const { Option } = Select;

export const HelpCenter = (props) => {
  const { url, path } = useRouteMatch();
  const history = useHistory();
  const {faqList, faqCategories, privacyPolicy, purchasePolicy, deliveryPolicy, creatorTerms, leaderTerms, partnerTerms, providerTerms, serviceTerms, vendorTerms} = useSelector(selectFAQ);
  const [activeTab, setActiveTab] = useState("1");
  const [faqPageSize, setfaqPageSize] = useState(10);
  const [searchValues, setSearchValues] = useState([]);
  const [isSearching, setSearching] = useState(false);
  const dispatcher = useDispatch();

  const initialFetch = async () =>{
    await dispatcher(fetchPrivacyPolicies('PRIVACY'));
    await dispatcher(fetchPurchasePolicies('PURCHASE'));
    await dispatcher(fetchDeliveryPolicies('DELIVERY'));
    await dispatcher(fetchCreatorTerms('Creator'));
    await dispatcher(fetchLeaderTerms('Leader'));
    await dispatcher(fetchPartnerTerms('Partner'));
    await dispatcher(fetchProviderTerms('Provider'));
    await dispatcher(fetchServiceTerms('Service'));
    await dispatcher(fetchVendorTerms('Vendor'));
  } 

  useEffect(() => {
    initialFetch()
  }, [])
  
  const [addfaqProps, setAddfaqProps] = useState({
    loading: false,
    isModalVisible: false,
  });

  const openAddfaqModal = () => {
    setAddfaqProps((prevState) => ({
      ...prevState,
      isModalVisible: true,
    }));
  };

  const handleCloseAddfaqModal = () => {
    setAddfaqProps((prevState) => ({
      ...prevState,
      isModalVisible: false,
    }));
  };

  const handleAddfaqInputChange = (e) =>{
    setAddfaqProps({
      ...addfaqProps,
      [e.target.name]: e.target.value
    })

   
  }

  const handleAddfaqCategoryChange = (val) =>{
    setAddfaqProps({
      ...addfaqProps,
      faqCategory: val
    })
  }

  const handleAddfaqStatusChange = (val) =>{
    setAddfaqProps({
      ...addfaqProps,
      status: val
    })
  }

  const handleAddfaq = async (val) =>{
    try{
      await Services.addFAQ(addfaqProps);
      
      setAddfaqProps((prevState) => ({
        ...prevState,
        isModalVisible: false,
      }));

      await dispatcher(fetchAllFAQs({page: 0, pageSize: 10}));

      notificationAlert(
        "success",
        "New FAQ Created",
        `New FAQ has been created`
      );
    }catch(error){
      setAddfaqProps((prevState) => ({
        ...prevState,
        isModalVisible: false,
      }));

      if (error.response) {
        notificationAlert(
          "error",
          "Error Occurred",
          error.response.data.message || "Please try again later"
        );
      } else {
        notificationAlert("error", "Error Occurred", "Please try again later");
      }
    }
  }




  const [editfaqProps, setEditfaqProps] = useState({
    loading: false,
    id: "",
    question: "",
    answer: "",
    faqCategory: null,
    isModalVisible: false,
  });

  const openEditfaqModal = (faq) => () => {
    setEditfaqProps((prevState) => ({
      ...prevState,
      id: faq.id,
      question: faq.question,
      answer: faq.answer,
      faqCategory: faq.faqCategory?.id,
      status: faq.status,
      isModalVisible: true,
    }));
  };

  const handleCloseEditfaqModal = () => {
    setEditfaqProps((prevState) => ({
      ...prevState,
      isModalVisible: false,
    }));
  };

  const handleEditfaqInputChange = (e) =>{
    setEditfaqProps({
      ...editfaqProps,
      [e.target.name]: e.target.value
    })

   
  }

  const handleEditfaqCategoryChange = (val) =>{
    setEditfaqProps({
      ...editfaqProps,
      faqCategory: val
    })
  }

  const handleEditfaqStatusChange = (val) =>{
    setEditfaqProps({
      ...editfaqProps,
      status: val
    })
  }

  const handleEditfaq = async (val) =>{
    try{
      await Services.updateFAQ(editfaqProps);
      
      setEditfaqProps((prevState) => ({
        ...prevState,
        isModalVisible: false,
      }));

      await dispatcher(fetchAllFAQs({page: 0, pageSize: 10}));

      notificationAlert(
        "success",
        "FAQ Updated",
        `FAQ has been updated`
      );
    }catch(error){
      setEditfaqProps((prevState) => ({
        ...prevState,
        isModalVisible: false,
      }));

      if (error.response) {
        notificationAlert(
          "error",
          "Error Occurred",
          error.response.data.message || "Please try again later"
        );
      } else {
        notificationAlert("error", "Error Occurred", "Please try again later");
      }
    }
  }

  const [deletefaqProps, setDeletefaqProps] = useState({
    loading: false,
    id: "",
    question: "",
    answer: "",
    faqCategory: null,
    isModalVisible: false,
  });

  const openDeletefaqModal = (faq) => () => {
    setDeletefaqProps((prevState) => ({
      ...prevState,
      id: faq.id,
      isModalVisible: true,
    }));
  };

  const handleCloseDeletefaqModal = () => {
    setDeletefaqProps((prevState) => ({
      ...prevState,
      isModalVisible: false,
    }));
  };

  const handleDeletefaq = async (val) =>{
    try{
      await Services.deleteFAQ(deletefaqProps);
      
      setDeletefaqProps((prevState) => ({
        ...prevState,
        isModalVisible: false,
      }));

      await dispatcher(fetchAllFAQs({page: 0, pageSize: 10}));

      notificationAlert(
        "success",
        "FAQ Deleted",
        `FAQ has been Deleted`
      );
    }catch(error){
      setDeletefaqProps((prevState) => ({
        ...prevState,
        isModalVisible: false,
      }));

      if (error.response) {
        notificationAlert(
          "error",
          "Error Occurred",
          error.response.data.message || "Please try again later"
        );
      } else {
        notificationAlert("error", "Error Occurred", "Please try again later");
      }
    }
  }


  const [viewfaqCategoryProps, setViewfaqCategoryProps] = useState({
    loading: false,
    isModalVisible: false,
  });

  const openViewfaqCategoryModal = () => {
    setViewfaqCategoryProps((prevState) => ({
      ...prevState,
      isModalVisible: true,
    }));
  };

  const handleCloseViewfaqCategoryModal = () => {
    setViewfaqCategoryProps((prevState) => ({
      ...prevState,
      isModalVisible: false,
    }));
  };



  const [addfaqCategoryProps, setAddfaqCategoryProps] = useState({
    loading: false,
    isModalVisible: false,
  });

  const openAddfaqCategoryModal = () => {
    setAddfaqCategoryProps((prevState) => ({
      ...prevState,
      isModalVisible: true,
    }));
  };

  const handleCloseAddfaqCategoryModal = () => {
    setAddfaqCategoryProps((prevState) => ({
      ...prevState,
      isModalVisible: false,
    }));
  };

  const handleAddfaqCategoryInputChange = (e) =>{
    setAddfaqCategoryProps({
      ...addfaqCategoryProps,
      [e.target.name]: e.target.value
    })  
  }


  const handleAddfaqCategoryStatusChange = (val) =>{
    setAddfaqCategoryProps({
      ...addfaqCategoryProps,
      status: val
    })
  }

  const handleAddfaqCategory = async (val) =>{
    try{
      await Services.addFAQCategory(addfaqCategoryProps);
      
      setAddfaqCategoryProps((prevState) => ({
        ...prevState,
        isModalVisible: false,
      }));

      await dispatcher(fetchfaqCategories({page: 0, pageSize: 10}));

      notificationAlert(
        "success",
        "New FAQ Category Created",
        `New FAQ Category has been created`
      );
    }catch(error){
      setAddfaqCategoryProps((prevState) => ({
        ...prevState,
        isModalVisible: false,
      }));

      if (error.response) {
        notificationAlert(
          "error",
          "Error Occurred",
          error.response.data.message || "Please try again later"
        );
      } else {
        notificationAlert("error", "Error Occurred", "Please try again later");
      }
    }
  }

  const [editfaqCategoryProps, setEditfaqCategoryProps] = useState({
    loading: false,
    id: "",
    name: "",
    description: "",
    isModalVisible: false,
  });

  const openEditfaqCategoryModal = (category) => () => {
    setEditfaqCategoryProps((prevState) => ({
      ...prevState,
      id: category.id,
      name: category.name,
      description: category.description,
      status: category.status,
      isModalVisible: true,
    }));
  };

  const handleCloseEditfaqCategoryModal = () => {
    setEditfaqCategoryProps((prevState) => ({
      ...prevState,
      isModalVisible: false,
    }));
  };

  const handleEditfaqCategoryInputChange = (e) =>{
    setEditfaqCategoryProps({
      ...editfaqCategoryProps,
      [e.target.name]: e.target.value
    })
   
  }

  const handleEditfaqCategoryStatusChange = (val) =>{
    setEditfaqCategoryProps({
      ...editfaqCategoryProps,
      status: val
    })
  }

  const handleEditfaqCategory = async (val) =>{
    try{
      await Services.updateFAQCategory(editfaqCategoryProps);
      
      setEditfaqCategoryProps((prevState) => ({
        ...prevState,
        isModalVisible: false,
      }));

      await dispatcher(fetchfaqCategories({page: 0, pageSize: 10}));

      notificationAlert(
        "success",
        "FAQ Category Updated",
        `FAQ Category has been updated`
      );
    }catch(error){
      setEditfaqProps((prevState) => ({
        ...prevState,
        isModalVisible: false,
      }));

      if (error.response) {
        notificationAlert(
          "error",
          "Error Occurred",
          error.response.data.message || "Please try again later"
        );
      } else {
        notificationAlert("error", "Error Occurred", "Please try again later");
      }
    }
  }

  const [deletefaqCategoryProps, setDeletefaqCategoryProps] = useState({
    loading: false,
    id: "",
    question: "",
    answer: "",
    faqCategory: null,
    isModalVisible: false,
  });

  const openDeletefaqCategoryModal = (faq) => () => {
    setDeletefaqCategoryProps((prevState) => ({
      ...prevState,
      id: faq.id,
      isModalVisible: true,
    }));
  };

  const handleCloseDeletefaqCategoryModal = () => {
    setDeletefaqCategoryProps((prevState) => ({
      ...prevState,
      isModalVisible: false,
    }));
  };

  const handleDeletefaqCategory = async (val) =>{
    try{
      await Services.deleteFAQCategory(deletefaqCategoryProps);
      
      setDeletefaqCategoryProps((prevState) => ({
        ...prevState,
        isModalVisible: false,
      }));

      await dispatcher(fetchfaqCategories({page: 0, pageSize: 10}));

      notificationAlert(
        "success",
        "FAQ Deleted",
        `FAQ has been Deleted`
      );
    }catch(error){
      setDeletefaqCategoryProps((prevState) => ({
        ...prevState,
        isModalVisible: false,
      }));

      if (error.response) {
        notificationAlert(
          "error",
          "Error Occurred",
          error.response.data.message || "Please try again later"
        );
      } else {
        notificationAlert("error", "Error Occurred", "Please try again later");
      }
    }
  }


  const faqCategoriesList = faqCategories?.object?.map((item, index) => {
    return (
      <Option key={index} value={item.id}>
        {item.name}
      </Option>
    );
  });



  const columns = [
    {
      title: "Question",
      dataIndex: "question",
      key: "question",
    },
    {
      title: "Answers",
      dataIndex: "answer",
      key: "answer",
    },
    {
      title: "Category",
      dataIndex: 'faqCategory',
      key: "faqCategory",
      render: (faqCategory, alldata) => {
        return (
          <p>{faqCategory?.name}</p>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: ({ status }, alldata) => {
        return alldata?.status === "ACTIVE" ? (
          <Tag color="#87d068">{alldata?.status}</Tag>
        ) : alldata?.status === "INACTIVE" ? (
          <Tag color="#FF9800">{alldata?.status}</Tag>
        ) : (
          <Tag color="#f50">{alldata?.status}</Tag>
        );
      },
    },

    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (action, alldata) => {
        const content = (
          <div>
            <p
            onClick={
              openEditfaqModal(alldata)
            }
              style={{ cursor: "pointer" }}
            >
              Edit
            </p>
            <p
            onClick={
              openDeletefaqModal(alldata)
            }
              style={{ cursor: "pointer" }}
            >
              Delete
            </p>
          </div>
        );

        return (
          <Popover trigger="click" content={content}>
            <FiMoreVertical />
          </Popover>
        );
      },
    },
  ];

  const faqCategoriescolumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: ({ status }, alldata) => {
        return alldata?.status === "ACTIVE" ? (
          <Tag color="#87d068">{alldata?.status}</Tag>
        ) : alldata?.status === "INACTIVE" ? (
          <Tag color="#FF9800">{alldata?.status}</Tag>
        ) : (
          <Tag color="#f50">{alldata?.status}</Tag>
        );
      },
    },

    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (action, alldata) => {
        const content = (
          <div>
            <p
            onClick={
              openEditfaqCategoryModal(alldata)
            }
              style={{ cursor: "pointer" }}
            >
              Edit
            </p>
            <p
            onClick={
              openDeletefaqCategoryModal(alldata)
            }
              style={{ cursor: "pointer" }}
            >
              Delete
            </p>
          </div>
        );

        return (
          <Popover trigger="click" content={content}>
            <FiMoreVertical />
          </Popover>
        );
      },
    },
  ];


  const [addPolicyProps, setAddPolicyProps] = useState({
    loading: false,
    policyType: "",
    textBody: "",
    title: "",
    isModalVisible: false,
  });

  const openAddPolicyModal = (type) => {
    setAddPolicyProps((prevState) => ({
      ...prevState,
      policyType: type,
      isModalVisible: true,
    }));
  };

  const handleCloseAddPolicyModal = () => {
    setAddPolicyProps((prevState) => ({
      ...prevState,
      isModalVisible: false,
    }));
  };

  const handleAddPolicyInputChange = (e) =>{
    setAddPolicyProps({
      ...addPolicyProps,
      title: e.target.value
    })  
  }


  const handleAddPolicyTextChange = (val) =>{
    setAddPolicyProps({
      ...addPolicyProps,
      textBody: val
    })
  }

  const handleAddPolicy = async (val) =>{
    try{
      await Services.addPolicy(addPolicyProps);
      
      setAddPolicyProps((prevState) => ({
        ...prevState,
        isModalVisible: false,
      }));

      
      if(addPolicyProps.policyType === 'PRIVACY'){
        await dispatcher(fetchPrivacyPolicies(addPolicyProps.policyType));
      }else if(addPolicyProps.policyType === 'PURCHASE'){
        await dispatcher(fetchPurchasePolicies(addPolicyProps.policyType));
      }else{
        await dispatcher(fetchDeliveryPolicies(addPolicyProps.policyType));
      }

      notificationAlert(
        "success",
        "New Policy Added",
        `New Policy Added Successfully`
      );
    }catch(error){
      setAddPolicyProps((prevState) => ({
        ...prevState,
        isModalVisible: false,
      }));

      if (error.response) {
        notificationAlert(
          "error",
          "Error Occurred",
          error.response.data.message || "Please try again later"
        );
      } else {
        notificationAlert("error", "Error Occurred", "Please try again later");
      }
    }
  }


  const [editPolicyProps, setEditPolicyProps] = useState({
    loading: false,
    policyType: "",
    textBody: "",
    title: "",
    isModalVisible: false,
  });

  const openEditPolicyModal = (details, type) => {
    setEditPolicyProps((prevState) => ({
      ...prevState,
      id: details.id,
      policyType: type,
      textBody: details.textBody,
      title: details.title,
      isModalVisible: true,
    }));
  };

  const handleCloseEditPolicyModal = () => {
    setEditPolicyProps((prevState) => ({
      ...prevState,
      isModalVisible: false,
    }));
  };

  const handleEditPolicyInputChange = (e) =>{
    setEditPolicyProps({
      ...editPolicyProps,
      title: e.target.value
    })  
  }


  const handleEditPolicyTextChange = (val) =>{
    setEditPolicyProps({
      ...editPolicyProps,
      textBody: val
    })
  }

  const handleEditPolicy = async (val) =>{
    try{
      await Services.updatePolicy(editPolicyProps);
      
      if(editPolicyProps.policyType === 'PRIVACY'){
        await dispatcher(fetchPrivacyPolicies(editPolicyProps.policyType));
      }else if(editPolicyProps.policyType === 'PURCHASE'){
        await dispatcher(fetchPurchasePolicies(editPolicyProps.policyType));
      }else{
        await dispatcher(fetchDeliveryPolicies(editPolicyProps.policyType));
      }
  
      setEditPolicyProps((prevState) => ({
        ...prevState,
        isModalVisible: false,
      }));

      notificationAlert(
        "success",
        "Policy Updated",
        `Policy Updated Successfully`
      );
    }catch(error){
      setEditPolicyProps((prevState) => ({
        ...prevState,
        isModalVisible: false,
      }));

      if (error.response) {
        notificationAlert(
          "error",
          "Error Occurred",
          error.response.data.message || "Please try again later"
        );
      } else {
        notificationAlert("error", "Error Occurred", "Please try again later");
      }
    }
  }



  
  const [addTermsProps, setAddTermsProps] = useState({
    loading: false,
    termsType: "",
    textBody: "",
    title: "",
    isModalVisible: false,
  });

  const openAddTermsModal = (type) => {
    setAddTermsProps((prevState) => ({
      ...prevState,
      termsType: type,
      isModalVisible: true,
    }));
  };

  const handleCloseAddTermsModal = () => {
    setAddTermsProps((prevState) => ({
      ...prevState,
      isModalVisible: false,
    }));
  };

  const handleAddTermsInputChange = (e) =>{
    setAddTermsProps({
      ...addTermsProps,
      title: e.target.value
    })  
  }


  const handleAddTermsTextChange = (val) =>{
    setAddTermsProps({
      ...addTermsProps,
      textBody: val
    })
  }

  const handleAddTerms = async (val) =>{
    try{
      await Services.addTerms(addTermsProps);
      
      setAddTermsProps((prevState) => ({
        ...prevState,
        isModalVisible: false,
      }));

      
      if(addTermsProps.termsType === 'Creator'){
        await dispatcher(fetchCreatorTerms(addTermsProps.termsType));
      }else if(addTermsProps.policyType === 'Leader'){
        await dispatcher(fetchLeaderTerms(addTermsProps.termsType));
      }else if(addTermsProps.policyType === 'Partner'){
        await dispatcher(fetchPartnerTerms(addTermsProps.termsType));
      }else if(addTermsProps.policyType === 'Provider'){
        await dispatcher(fetchProviderTerms(addTermsProps.termsType));
      }else if(addTermsProps.policyType === 'Service'){
        await dispatcher(fetchServiceTerms(addTermsProps.termsType));
      }else{
        await dispatcher(fetchVendorTerms(addTermsProps.termsType));
      }

      notificationAlert(
        "success",
        "New Term Added",
        `New Term Added Successfully`
      );
    }catch(error){
      setAddTermsProps((prevState) => ({
        ...prevState,
        isModalVisible: false,
      }));

      if (error.response) {
        notificationAlert(
          "error",
          "Error Occurred",
          error.response.data.message || "Please try again later"
        );
      } else {
        notificationAlert("error", "Error Occurred", "Please try again later");
      }
    }
  }


  const [editTermsProps, setEditTermsProps] = useState({
    loading: false,
    termsType: "",
    textBody: "",
    title: "",
    isModalVisible: false,
  });

  const openEditTermsModal = (details,type) => {
    setEditTermsProps((prevState) => ({
      ...prevState,
      id: details.id,
      termsType: type,
      textBody: details.textBody,
      title: details.title,
      isModalVisible: true,
    }));
  };

  const handleCloseEditTermsModal = () => {
    setEditTermsProps((prevState) => ({
      ...prevState,
      isModalVisible: false,
    }));
  };

  const handleEditTermsInputChange = (e) =>{
    setEditTermsProps({
      ...editTermsProps,
      title: e.target.value
    })  
  }


  const handleEditTermsTextChange = (val) =>{
    setEditTermsProps({
      ...editTermsProps,
      textBody: val
    })
  }

  const handleEditTerms = async (val) =>{
    try{
      await Services.editTerms(editTermsProps);
      
      setEditTermsProps((prevState) => ({
        ...prevState,
        isModalVisible: false,
      }));

      
      if(editTermsProps.termsType === 'Creator'){
        await dispatcher(fetchCreatorTerms(editTermsProps.termsType));
      }else if(editTermsProps.policyType === 'Leader'){
        await dispatcher(fetchLeaderTerms(editTermsProps.termsType));
      }else if(editTermsProps.policyType === 'Partner'){
        await dispatcher(fetchPartnerTerms(editTermsProps.termsType));
      }else if(editTermsProps.policyType === 'Provider'){
        await dispatcher(fetchProviderTerms(editTermsProps.termsType));
      }else if(editTermsProps.policyType === 'Service'){
        await dispatcher(fetchServiceTerms(editTermsProps.termsType));
      }else{
        await dispatcher(fetchVendorTerms(editTermsProps.termsType));
      }

      notificationAlert(
        "success",
        "New Term Added",
        `New Term Added Successfully`
      );
    }catch(error){
      setEditTermsProps((prevState) => ({
        ...prevState,
        isModalVisible: false,
      }));

      if (error.response) {
        notificationAlert(
          "error",
          "Error Occurred",
          error.response.data.message || "Please try again later"
        );
      } else {
        notificationAlert("error", "Error Occurred", "Please try again later");
      }
    }
  }



  const gotoAllUserTable = () => {
    history.push(`${url}/table`);
  };
  const handleRow = (type) => (record, rowIndex) => {
    return {
      onDoubleClick: (event) => {
        localStorage.userStatus = type;
        history.push(`${url}/${record.email}`);
      },
    };
  };

  const onTabChange = (key) => {
    setActiveTab(key);
  };

  const handlefaqPagination = (page, pageSize) => {
    setfaqPageSize(pageSize);
    dispatcher(fetchAllFAQs({ page: page - 1, pageSize, status: "ACTIVE" }));
  };

  const handlefaqCategoriesPagination = (page, pageSize) => {
    setfaqPageSize(pageSize);
    dispatcher(fetchfaqCategories({ page: page - 1, pageSize, status: "ACTIVE" }));
  };

  const handleUserSearch = (event) => {
    (async () => {
      try {
        console.log(event);
        setSearching(Boolean(event.target.value))
        const response = await Services.searchUser({
          search: event.target.value,
        });
        setSearchValues(response.data.data.body);
        console.log(response.data.data.body);
      } catch (error) {
        console.log(error);
      }
    })();
  };

  return (
    <>
      <Switch>
        <Route exact path={`${path}`}>
          <MainPageScaffold onSearch={handleUserSearch} showSearch>
            <PageTitleBar hideButtons={true} title="Help Center" />
            <TableComponent onClick={gotoAllUserTable}>
              {isSearching ? (
                <StyledAntTable
                  onRow={handleRow("active")}
                  columns={columns}
                  dataSource={searchValues}
                  pagination={false}
                />
              ) : (
                <Tabs
                  tabBarStyle={{ color: "#A0AEC0", padding: "0px 23px" }}
                  defaultActiveKey="1"
                  onChange={onTabChange}
                >
                  <TabPane tab="FAQs" key="1">
                  <div className="repush-btn-div">
                    <div>
                        <PrimaryButton onClick={() => openAddfaqModal()} text='Add New FAQ'/>
                    </div>
                    <div>
                        <PrimaryButton className='mx-2' onClick={openViewfaqCategoryModal} text='View FAQ Categories'/>
                    </div>
                  </div>
                    <StyledAntTable
                      onRow={handleRow("active")}
                      columns={columns}
                      dataSource={faqList?.data}
                      pagination={{
                        total: faqList?.total,
                        showTotal: (total, range) =>
                          `${range[0]}-${range[1]} of ${total} items`,
                        defaultCurrent: 1,
                        current: faqList?.page + 1,
                        pageSize: faqPageSize,
                        showSizeChanger: true,
                        onChange: handlefaqPagination,
                      }}
                    />
                  </TabPane>

                  <TabPane tab="Policies" key="2">
                    <div className="padding-2">
                      <Collapse bordered={false} size='large'>
                        <Panel header="Privacy Policy" key="1">
                        <div className="repush-btn-div">
                          {privacyPolicy?.length >= 1? 
                          <Button onClick={() => openEditPolicyModal(privacyPolicy[0], 'PRIVACY')} >Update Privacy Policy</Button>
                          : <Button onClick={() => openAddPolicyModal('PRIVACY')} >Add Privacy Policy</Button>}
                        </div>
                        <div  className="padding-2 bg-white">
                            {privacyPolicy?.length >= 1?
                            <>
                              <h2>{privacyPolicy[0].title}</h2>

                              <div dangerouslySetInnerHTML={{__html: privacyPolicy[0].textBody}}></div>
                            </>
                            : null}
                        </div>
                        </Panel>
                        <Panel header="Purchase Policy" key="2">
                            <div className="repush-btn-div">
                              {purchasePolicy?.length >= 1? 
                              <Button onClick={() => openEditPolicyModal(purchasePolicy[0], 'PURCHASE')} >Update Purchase Policy</Button>
                              : <Button onClick={() => openAddPolicyModal('PURCHASE')} >Add Purchase Policy</Button>}
                            </div>
                            <div  className="padding-2 bg-white">
                                {purchasePolicy?.length >= 1?
                                <>
                                  <h2>{purchasePolicy[0].title}</h2>

                                  <div dangerouslySetInnerHTML={{__html: purchasePolicy[0].textBody}}></div>
                                </>
                                : null}
                            </div>
                        </Panel>
                        <Panel header="Delivery Policy" key="3">
                        <div className="repush-btn-div">
                          {deliveryPolicy?.length >= 1? 
                          <Button onClick={() => openEditPolicyModal(deliveryPolicy[0], 'DELIVERY')} >Update Delivery Policy</Button>
                          : <Button onClick={() => openAddPolicyModal('DELIVERY')} >Add Delivery Policy</Button>}
                        </div>
                        <div  className="padding-2 bg-white">
                            {deliveryPolicy?.length >= 1?
                            <>
                              <h2>{deliveryPolicy[0].title}</h2>

                              <div dangerouslySetInnerHTML={{__html: deliveryPolicy[0].textBody}}></div>
                            </>
                            : null}
                        </div>
                        </Panel>
                      </Collapse>
                    </div>
                  </TabPane>

                  <TabPane tab="Terms & Conditions" key="3">
                    <div className="padding-2">
                      <Collapse bordered={false} size='large' defaultActiveKey={['1']}>
                        <Panel header="Creator T/C" key="1">
                          <div className="repush-btn-div">
                            {creatorTerms?.length >= 1? 
                            <Button onClick={() => openEditTermsModal(creatorTerms[0], 'Creator')} >Update Creator T/C</Button>
                            : <Button onClick={() => openAddTermsModal('Creator')} >Add Creator T/C</Button>}
                          </div>
                          <div  className="padding-2 bg-white">
                              {creatorTerms?.length >= 1?
                              <>
                                <h2>{creatorTerms[0].title}</h2>

                                <div dangerouslySetInnerHTML={{__html: creatorTerms[0].textBody}}></div>
                              </>
                              : null}
                          </div>
                        </Panel>
                        <Panel header="Partner T/C" key="2">
                          <div className="repush-btn-div">
                            {partnerTerms?.length >= 1? 
                            <Button onClick={() => openEditTermsModal(partnerTerms[0], 'Partner')} >Update Partner T/C</Button>
                            : <Button onClick={() => openAddTermsModal('Partner')} >Add Partner T/C</Button>}
                          </div>
                          <div  className="padding-2 bg-white">
                              {partnerTerms?.length >= 1?
                              <>
                                <h2>{partnerTerms[0].title}</h2>

                                <div dangerouslySetInnerHTML={{__html: partnerTerms[0].textBody}}></div>
                              </>
                              : null}
                          </div>
                        </Panel>
                        <Panel header="Vendor T/C" key="3">
                          <div className="repush-btn-div">
                            {vendorTerms?.length >= 1? 
                            <Button onClick={() => openEditTermsModal(vendorTerms[0], 'Vendor')} >Update Vendor T/C</Button>
                            : <Button onClick={() => openAddTermsModal('Vendor')} >Add Vendor T/C</Button>}
                          </div>
                          <div  className="padding-2 bg-white">
                              {vendorTerms?.length >= 1?
                              <>
                                <h2>{vendorTerms[0].title}</h2>

                                <div dangerouslySetInnerHTML={{__html: vendorTerms[0].textBody}}></div>
                              </>
                              : null}
                          </div>
                        </Panel>
                        <Panel header="Service T/C" key="4">
                          <div className="repush-btn-div">
                            {serviceTerms?.length >= 1? 
                            <Button onClick={() => openEditTermsModal(serviceTerms[0], 'Service')} >Update Service T/C</Button>
                            : <Button onClick={() => openAddTermsModal('Service')} >Add Service T/C</Button>}
                          </div>
                          <div  className="padding-2 bg-white">
                              {serviceTerms?.length >= 1?
                              <>
                                <h2>{serviceTerms[0].title}</h2>

                                <div dangerouslySetInnerHTML={{__html: serviceTerms[0].textBody}}></div>
                              </>
                              : null}
                          </div>
                        </Panel>
                        <Panel header="Provider T/C" key="5">
                          <div className="repush-btn-div">
                            {providerTerms?.length >= 1? 
                            <Button onClick={() => openEditTermsModal(providerTerms[0], 'Provider')} >Update Provider T/C</Button>
                            : <Button onClick={() => openAddTermsModal('Provider')} >Add Provider T/C</Button>}
                          </div>
                          <div  className="padding-2 bg-white">
                              {providerTerms?.length >= 1?
                              <>
                                <h2>{providerTerms[0].title}</h2>

                                <div dangerouslySetInnerHTML={{__html: providerTerms[0].textBody}}></div>
                              </>
                              : null}
                          </div>
                        </Panel>
                        <Panel header="Leader T/C" key="5">
                          <div className="repush-btn-div">
                            {leaderTerms?.length >= 1? 
                            <Button onClick={() => openEditTermsModal(leaderTerms[0], 'Leader')} >Update Leader T/C</Button>
                            : <Button onClick={() => openAddTermsModal('Leader')} >Add Leader T/C</Button>}
                          </div>
                          <div  className="padding-2 bg-white">
                              {leaderTerms?.length >= 1?
                              <>
                                <h2>{leaderTerms[0].title}</h2>

                                <div dangerouslySetInnerHTML={{__html: leaderTerms[0].textBody}}></div>
                              </>
                              : null}
                          </div>
                        </Panel>
                      </Collapse>
                    </div>
                  </TabPane>
                </Tabs>
              )}
            </TableComponent>

            <AddfaqModal
              question={addfaqProps.question}
              answer={addfaqProps.answer}
              faqCategory={addfaqProps.faqCategory}
              faqCategoriesList={faqCategoriesList}
              loading={addfaqProps.loading}
              visible={addfaqProps.isModalVisible}
              status={addfaqProps.status}
              onCancel={handleCloseAddfaqModal}
              onInputChange = {val => handleAddfaqInputChange(val)}
              onCategoryChange = {val => handleAddfaqCategoryChange(val)}
              onStatusChange = {val => handleAddfaqStatusChange(val)}
              onOk={handleAddfaq}
            />

            <EditfaqModal
              id={editfaqProps.id}
              question={editfaqProps.question}
              answer={editfaqProps.answer}
              faqCategory={editfaqProps.faqCategory}
              faqCategoriesList={faqCategoriesList}
              loading={editfaqProps.loading}
              visible={editfaqProps.isModalVisible}
              status={editfaqProps.status}
              onCancel={handleCloseEditfaqModal}
              onInputChange = {val => handleEditfaqInputChange(val)}
              onCategoryChange = {val => handleEditfaqCategoryChange(val)}
              onStatusChange = {val => handleEditfaqStatusChange(val)}
              onOk={handleEditfaq}
            />

            <DeletefaqModal
              id={deletefaqProps.id}
              loading={deletefaqProps.loading}
              visible={deletefaqProps.isModalVisible}
              onCancel={handleCloseDeletefaqModal}
              onOk={handleDeletefaq}
            />

            <AddfaqCategoryModal
              name={addfaqCategoryProps.question}
              description={addfaqCategoryProps.answer}
              loading={addfaqCategoryProps.loading}
              visible={addfaqCategoryProps.isModalVisible}
              status={addfaqCategoryProps.status}
              onCancel={handleCloseAddfaqCategoryModal}
              onInputChange = {val => handleAddfaqCategoryInputChange(val)}
              onStatusChange = {val => handleAddfaqCategoryStatusChange(val)}
              onOk={handleAddfaqCategory}
            />

            <ViewfaqCategoryModal
              loading={viewfaqCategoryProps.loading}
              visible={viewfaqCategoryProps.isModalVisible}
              tableColumns={faqCategoriescolumns}
              pagination={handlefaqCategoriesPagination}
              dataSource={faqCategories}
              pageSize={faqPageSize}
              handleRow={handleRow("active")}
              onCancel={handleCloseViewfaqCategoryModal}
              onAddCategory={() => openAddfaqCategoryModal()}
            />

            <EditfaqCategoryModal
              name={editfaqCategoryProps.name}
              description={editfaqCategoryProps.description}
              loading={editfaqCategoryProps.loading}
              visible={editfaqCategoryProps.isModalVisible}
              status={editfaqCategoryProps.status}
              onCancel={handleCloseEditfaqCategoryModal}
              onInputChange = {val => handleEditfaqCategoryInputChange(val)}
              onStatusChange = {val => handleEditfaqCategoryStatusChange(val)}
              onOk={handleEditfaqCategory}
            />

            <DeletefaqCategoryModal
              id={deletefaqCategoryProps.id}
              loading={deletefaqCategoryProps.loading}
              visible={deletefaqCategoryProps.isModalVisible}
              onCancel={handleCloseDeletefaqCategoryModal}
              onOk={handleDeletefaqCategory}
            />

            <AddPolicyModal
              title={addPolicyProps.title}
              policyType={addPolicyProps.policyType}
              textBody={addPolicyProps.textBody}
              loading={addPolicyProps.loading}
              visible={addPolicyProps.isModalVisible}
              onCancel={handleCloseAddPolicyModal}
              onInputChange = {val => handleAddPolicyInputChange(val)}
              onBodyChange = {val => handleAddPolicyTextChange(val)}
              onOk={handleAddPolicy}
            />

            <EditPolicyModal
              title={editPolicyProps.title}
              policyType={editPolicyProps.policyType}
              textBody={editPolicyProps.textBody}
              loading={editPolicyProps.loading}
              visible={editPolicyProps.isModalVisible}
              onCancel={handleCloseEditPolicyModal}
              onInputChange = {val => handleEditPolicyInputChange(val)}
              onBodyChange = {val => handleEditPolicyTextChange(val)}
              onOk={handleEditPolicy}
            />


            <AddTermsModal
              title={addTermsProps.title}
              termsType={addTermsProps.termsType}
              textBody={addTermsProps.textBody}
              loading={addTermsProps.loading}
              visible={addTermsProps.isModalVisible}
              onCancel={handleCloseAddTermsModal}
              onInputChange = {val => handleAddTermsInputChange(val)}
              onBodyChange = {val => handleAddTermsTextChange(val)}
              onOk={handleAddTerms}
            />

            <EditTermsModal
              title={editTermsProps.title}
              termsType={editTermsProps.termsType}
              textBody={editTermsProps.textBody}
              loading={editTermsProps.loading}
              visible={editTermsProps.isModalVisible}
              onCancel={handleCloseEditTermsModal}
              onInputChange = {val => handleEditTermsInputChange(val)}
              onBodyChange = {val => handleEditTermsTextChange(val)}
              onOk={handleEditTerms}
            />
            
          </MainPageScaffold>
        </Route>
      </Switch>
    </>
  );
};

export const AddfaqModal = (props) => {
  return (
    <StyledModal
      visible={props.visible}
      okButtonProps={{
        loading: props.loading,
        style: {
          backgroundColor: themes.primaryColor,
          border: `1px solid ${themes.primaryColor}`,
        },
      }}
      cancelButtonProps={{
        type: "danger",
      }}
      onOk={props.onOk}
      okText="Save"
      onCancel={props.onCancel}
    >
      <h3>Edit FAQ</h3>

      <Form
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      >
      <Form.Item
          label="FAQ Question"
          name="question"
          
        >
          <StyledInputContainer>
            <Input

              name="question"
              onChange={props.onInputChange}
              value={props.question}
              bordered={false}
            />
          </StyledInputContainer>
        </Form.Item>

        <Form.Item
          label="Answer"
          name="answer"
          
        >
          <StyledInputContainer>
            <Input
              
              name="answer"
              onChange={props.onInputChange}
              value={props.answer}
              bordered={false}
            />
          </StyledInputContainer>
        </Form.Item>

        <Form.Item
          label="Category"
          name="faqCategory"
          
        >
        <StyledInputContainer>
            <Select
              name="faqCategory"
              onChange={props.onCategoryChange}
              placeholder="Select Category"
              style={{ width: "100%", textAlign: "left" }}
              bordered={false}
              value={props.faqCategory}
            >
              {props.faqCategoriesList}
            </Select>
          </StyledInputContainer>
        </Form.Item>

        <Form.Item
          name="status"
          label="Status"
        >
          <StyledInputContainer>
            <Select
              name="status"
              onChange={props.onStatusChange}
              placeholder="Select status"
              value={props.status}
              bordered={false}
              style={{ width: "100%", textAlign: "left" }}
            >
              <Option value="ACTIVE">ACTIVE </Option>{" "}
              <Option value="INACTIVE"> INACTIVE </Option>{" "}
            </Select>
          </StyledInputContainer>
        </Form.Item>
      </Form>
    </StyledModal>
  );
};

export const EditfaqModal = (props) => {
  return (
    <StyledModal
      visible={props.visible}
      okButtonProps={{
        loading: props.loading,
        style: {
          backgroundColor: themes.primaryColor,
          border: `1px solid ${themes.primaryColor}`,
        },
      }}
      cancelButtonProps={{
        type: "danger",
      }}
      onOk={props.onOk}
      okText="Update"
      onCancel={props.onCancel}
    >
      <h3>Edit FAQ</h3>

      <Form
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      >
      <Form.Item
          label="FAQ Question"
          name="question"
          
        >
          <StyledInputContainer>
            <Input

              name="question"
              onChange={props.onInputChange}
              defaultValue={props.question}
              bordered={false}
            />
          </StyledInputContainer>
        </Form.Item>

        <Form.Item
          label="Answer"
          name="answer"
          
        >
          <StyledInputContainer>
            <Input
              
              name="answer"
              onChange={props.onInputChange}
              defaultValue={props.answer}
              bordered={false}
            />
          </StyledInputContainer>
        </Form.Item>

        <Form.Item
          label="Category"
          name="faqCategory"
          
        >
        <StyledInputContainer>
            <Select
              name="faqCategory"
              onChange={props.onCategoryChange}
              placeholder="Select Category"
              style={{ width: "100%", textAlign: "left" }}
              bordered={false}
              defaultValue={props.faqCategory}
            >
              {props.faqCategoriesList}
            </Select>
          </StyledInputContainer>
        </Form.Item>

        <Form.Item
          name="status"
          label="Status"
        >
          <StyledInputContainer>
            <Select
              name="status"
              onChange={props.onStatusChange}
              placeholder="Select status"
              defaultValue={props.status}
              bordered={false}
              style={{ width: "100%", textAlign: "left" }}
            >
              <Option value="ACTIVE">ACTIVE </Option>{" "}
              <Option value="INACTIVE"> INACTIVE  </Option>{" "}
            </Select>
          </StyledInputContainer>
        </Form.Item>
      </Form>
    </StyledModal>
  );
};

export const DeletefaqModal = (props) => {
  return (
    <StyledModal
      visible={props.visible}
      okButtonProps={{
        loading: props.loading,
        style: {
          backgroundColor: themes.primaryColor,
          border: `1px solid ${themes.primaryColor}`,
        },
      }}
      cancelButtonProps={{
        type: "danger",
      }}
      onOk={props.onOk}
      okText="Delete"
      onCancel={props.onCancel}
    >
      <h3>Delete FAQ</h3>
      <p>Are you sure you want to delete this FAQ</p>

    </StyledModal>
  );
};

export const ViewfaqCategoryModal = (props) => {
  return (
    <StyledModal
      footer={null}
      width={1000}
      visible={props.visible}
      onCancel={props.onCancel}
    >
      <div className="repush-btn-div">
        <div>
            <PrimaryButton className='mx-2' onClick={props.onAddCategory} text='Add FAQ Category'/>
        </div>
      </div>
      <StyledAntTable
        onRow={props.handleRow}
        columns={props.tableColumns}
        dataSource={props.dataSource?.object}
        pagination={{
          total: props.dataSource?.total,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          defaultCurrent: 1,
          current: props.dataSource?.page + 1,
          pageSize: props.pageSize,
          showSizeChanger: true,
          onChange: props.pagination,
        }}
      />

    </StyledModal>
  );
};

export const AddfaqCategoryModal = (props) => {
  return (
    <StyledModal
      visible={props.visible}
      okButtonProps={{
        loading: props.loading,
        style: {
          backgroundColor: themes.primaryColor,
          border: `1px solid ${themes.primaryColor}`,
        },
      }}
      cancelButtonProps={{
        type: "danger",
      }}
      onOk={props.onOk}
      okText="Save"
      onCancel={props.onCancel}
    >
      <h3>Add FAQ Category</h3>

      <Form
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      >
      <Form.Item
          label="Category Name"
          name="name"
          
        >
          <StyledInputContainer>
            <Input

              name="name"
              onChange={props.onInputChange}
              value={props.name}
              bordered={false}
            />
          </StyledInputContainer>
        </Form.Item>

        <Form.Item
          label="Description"
          name="description"
          
        >
          <StyledInputContainer>
            <Input
              
              name="description"
              onChange={props.onInputChange}
              value={props.description}
              bordered={false}
            />
          </StyledInputContainer>
        </Form.Item>


        <Form.Item
          name="status"
          label="Status"
        >
          <StyledInputContainer>
            <Select
              name="status"
              onChange={props.onStatusChange}
              placeholder="Select status"
              value={props.status}
              bordered={false}
              style={{ width: "100%", textAlign: "left" }}
            >
              <Option value="ACTIVE">ACTIVE </Option>{" "}
              <Option value="INACTIVE"> INACTIVE </Option>{" "}
            </Select>
          </StyledInputContainer>
        </Form.Item>
      </Form>
    </StyledModal>
  );
};

export const EditfaqCategoryModal = (props) => {
  return (
    <StyledModal
      visible={props.visible}
      okButtonProps={{
        loading: props.loading,
        style: {
          backgroundColor: themes.primaryColor,
          border: `1px solid ${themes.primaryColor}`,
        },
      }}
      cancelButtonProps={{
        type: "danger",
      }}
      onOk={props.onOk}
      okText="Update"
      onCancel={props.onCancel}
    >
      <h3>Add FAQ Category</h3>

      <Form
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      >
      <Form.Item
          label="Category Name"
          name="name"
          
        >
          <StyledInputContainer>
            <Input

              name="name"
              onChange={props.onInputChange}
              defaultValue={props.name}
              bordered={false}
            />
          </StyledInputContainer>
        </Form.Item>

        <Form.Item
          label="Description"
          name="description"
          
        >
          <StyledInputContainer>
            <Input
              
              name="description"
              onChange={props.onInputChange}
              defaultValue={props.description}
              bordered={false}
            />
          </StyledInputContainer>
        </Form.Item>


        <Form.Item
          name="status"
          label="Status"
        >
          <StyledInputContainer>
            <Select
              name="status"
              onChange={props.onStatusChange}
              placeholder="Select status"
              defaultValue={props.status}
              bordered={false}
              style={{ width: "100%", textAlign: "left" }}
            >
              <Option value="ACTIVE">ACTIVE </Option>{" "}
              <Option value="INACTIVE"> INACTIVE </Option>{" "}
            </Select>
          </StyledInputContainer>
        </Form.Item>
      </Form>
    </StyledModal>
  );
};

export const DeletefaqCategoryModal = (props) => {
  return (
    <StyledModal
      visible={props.visible}
      okButtonProps={{
        loading: props.loading,
        style: {
          backgroundColor: themes.primaryColor,
          border: `1px solid ${themes.primaryColor}`,
        },
      }}
      cancelButtonProps={{
        type: "danger",
      }}
      onOk={props.onOk}
      okText="Delete"
      onCancel={props.onCancel}
    >
      <h3>Delete FAQ Category</h3>
      <p>Are you sure you want to delete this Category</p>

    </StyledModal>
  );
};

export const AddPolicyModal = (props) => {
  return (
    <StyledModal
      width={1000}
      visible={props.visible}
      okButtonProps={{
        loading: props.loading,
        style: {
          backgroundColor: themes.primaryColor,
          border: `1px solid ${themes.primaryColor}`,
        },
      }}
      cancelButtonProps={{
        type: "danger",
      }}
      onOk={props.onOk}
      okText="Save"
      onCancel={props.onCancel}
    >  
      <Form
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      >
        <Form.Item
          label="Policy Name"
          name="title"
          
        >
          <StyledInputContainer>
            <Input

              name="title"
              onChange={props.onInputChange}
              value={props.title}
              bordered={false}
            />
          </StyledInputContainer>
        </Form.Item>
        <ReactQuill theme="snow"
         value={props.textBody} onChange={props.onBodyChange}
          />
      </Form>
    </StyledModal>
  );
};

export const EditPolicyModal = (props) => {
  return (
    <StyledModal
      width={1000}
      visible={props.visible}
      okButtonProps={{
        loading: props.loading,
        style: {
          backgroundColor: themes.primaryColor,
          border: `1px solid ${themes.primaryColor}`,
        },
      }}
      cancelButtonProps={{
        type: "danger",
      }}
      onOk={props.onOk}
      okText="Update"
      onCancel={props.onCancel}
    >  
      <Form
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      >
        <Form.Item
          label="Policy Name"
          name="title"
          
        >
          <StyledInputContainer>
            <Input

              name="title"
              onChange={props.onInputChange}
              defaultValue={props.title}
              bordered={false}
            />
          </StyledInputContainer>
        </Form.Item>
        <ReactQuill theme="snow"
         defaultValue={props.textBody} onChange={props.onBodyChange}
          />
      </Form>
    </StyledModal>
  );
};

export const AddTermsModal = (props) => {
  return (
    <StyledModal
      width={1000}
      visible={props.visible}
      okButtonProps={{
        loading: props.loading,
        style: {
          backgroundColor: themes.primaryColor,
          border: `1px solid ${themes.primaryColor}`,
        },
      }}
      cancelButtonProps={{
        type: "danger",
      }}
      onOk={props.onOk}
      okText="Save"
      onCancel={props.onCancel}
    >  
      <Form
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      >
        <Form.Item
          label="Terms Title"
          name="title"
          
        >
          <StyledInputContainer>
            <Input

              name="title"
              onChange={props.onInputChange}
              value={props.title}
              bordered={false}
            />
          </StyledInputContainer>
        </Form.Item>
        <ReactQuill theme="snow"
         value={props.textBody} onChange={props.onBodyChange}
          />
      </Form>
    </StyledModal>
  );
};

export const EditTermsModal = (props) => {
  return (
    <StyledModal
      width={1000}
      visible={props.visible}
      okButtonProps={{
        loading: props.loading,
        style: {
          backgroundColor: themes.primaryColor,
          border: `1px solid ${themes.primaryColor}`,
        },
      }}
      cancelButtonProps={{
        type: "danger",
      }}
      onOk={props.onOk}
      okText="Update"
      onCancel={props.onCancel}
    >  
      <Form
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      >
        <Form.Item
          label="Terms Title"
          name="title"
          
        >
          <StyledInputContainer>
            <Input

              name="title"
              onChange={props.onInputChange}
              defaultValue={props.title}
              bordered={false}
            />
          </StyledInputContainer>
        </Form.Item>
        <ReactQuill theme="snow"
         defaultValue={props.textBody} onChange={props.onBodyChange}
          />
      </Form>
    </StyledModal>
  );
};

const StyledAntTable = styled(AntTable)`
  & .ant-table-tbody > tr > td {
    cursor: pointer;
  }
`;

