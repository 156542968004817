import axios from "axios";
import domain from "./domain";
import { routes } from "./routes";
import getURLParams from "./utils/getParams";

export class Services{
    static login(payload){
        return axios({
            method: 'POST',
            baseURL: domain,
            url: `${routes.loginRoute}`,
            data: payload.data,
        })
    }

    static fetchAllUser(payload){
        const page = payload.page || 0;
        const size = payload.pageSize || 10;

        return axios({
            method: 'GET',
            baseURL: domain,
            url:`${routes.userRoute}?role=USER&page=${page}&size=${size}&status=${payload.status}`,
            headers: {
                Authorization: `Bearer ${localStorage.token}`
            }
        })
    }


    static searchUser(payload){
        const param = getURLParams(payload);
        return axios({
            method: 'GET',
            baseURL: domain,
            url:`${routes.userRoute}${param}`,
            headers: {
                Authorization: `Bearer ${localStorage.token}`
            }
        })
    }


    static fetchUserByEmail(payload){
        return axios({
            method: 'GET',
            baseURL: domain,
            url: `${routes.userRoute}/details/email?email=${payload.email}`,
            headers: {
                Authorization: `Bearer ${localStorage.token}`
            }
        })
    }

    static fetchDashboardStat(){
        return axios({
            method: 'GET',
            baseURL: domain,
            url: `${routes.getDashboardStatRoute}`,
            headers: {
                Authorization: `Bearer ${localStorage.token}`
            }
        })
    }

    static sendEmailVerification(payload){
        return axios({
            method: 'POST',
            baseURL: domain,
            url: `${routes.sendEmailVerificationRoute}/${payload.email}`,
            headers: {
                Authorization: `Bearer ${localStorage.token}`
            }
        })
    }

    static activateUser(payload){
        return axios({
            method: 'PUT',
            baseURL: domain,
            url: `${routes.activateUserRoute}`,
            headers: {
                Authorization: `Bearer ${localStorage.token}`
            },
            data: payload.data
        })
    }

    static suspendUser(payload){
        return axios({
            method: 'PUT',
            baseURL: domain,
            url: `${routes.suspendUserRoute}`,
            headers: {
                Authorization: `Bearer ${localStorage.token}`
            },
            data: payload.data
        })
    }

    static confirmEmail(payload){
        return axios({
            method: 'GET',
            baseURL: domain,
            url: `${routes.emailConfirmationRoute}${payload.token}`,
        })
    }

    static fetchTransactions(payload){
        const page = payload.page || 0;
        const size = payload.pageSize || 10;
        
        return axios({
            method: 'GET',
            baseURL: domain,
            url: `${routes.transactionRoute}?&page=${page}&size=${size}`,
            headers: {
                Authorization: `Bearer ${localStorage.token}`
            },
        })
    }

    static fetchTransactionsWithFilter(payload){
        const params = getURLParams({...payload}).replace('Successful', true).replace('Failed', false);
        return axios({
            method: 'GET',
            baseURL: domain,
            url: `${routes.transactionRoute}${params}`,
            headers: {
                Authorization: `Bearer ${localStorage.token}`
            },
        })
    }

    static fetchTransactionsByEmail(payload){
        const page = payload.page || 0;
        const size = payload.pageSize || 10;

        return axios({
            method: 'GET',
            baseURL: domain,
            url: `${routes.transactionRoute}users/customer-email?page=${page}&size=${size}&customer-email=${payload.email}`,
            headers: {
                Authorization: `Bearer ${localStorage.token}`
            },
        })
    }


    static requeryTransaction(payload){
        return axios({
            method: 'POST',
            baseURL: domain,
            url:`${routes.requeryRouteNew}`,
            headers: {
                Authorization: `Bearer ${localStorage.token}`
            },
            data: payload.data
        })
    }

    static async fetchAllNotification(payload){
        const params = getURLParams({...payload})
        return axios({
            method: 'GET',
            baseURL: domain,
            url: /* `http://134.209.64.28:8083/notifications/?credentials=%7B%7D&details=%7B%7D&page=1&principal=%7B%7D&size=10` */ `${routes.notificationRoute}/auth/${params}`,
            headers: {
                Authorization: `Bearer ${localStorage.token}`
            },
        })
    }


    static async CreateNotification(payload){
        return axios({
            method: 'POST',
            baseURL: domain,
            url: `${routes.notificationRoute}/?credentials=%7B%7D&details=%7B%7&principal=%7B%7D`,
            headers: {
                Authorization: `Bearer ${localStorage.token}`
            },
            data: payload.data
        })
    }


    static async updateNotification(payload){
        return axios({
            method: 'PUT',
            baseURL: domain,
            url: `${routes.notificationRoute}/${payload.id}`,
            headers: {
                Authorization: `Bearer ${localStorage.token}`
            },
            data: payload.data
        })
    }

    static async broadcastById(payload){
        return axios({
            method: 'POST',
            baseURL: domain,
            url: `${routes.notificationRoute}/broadcast/id/?id=${payload.id}`,
            headers: {
                Authorization: `Bearer ${localStorage.token}`
            },
        })
    }
    
    
    static async fetchAllServices(payload){
        const params = getURLParams({...payload});
        return axios({
            method: 'GET',
            baseURL: domain,
            url: `${routes.servicesRoute}/${params}`,
        })
    }

    static async createService(payload){
        return axios({
            method: 'POST',
            baseURL: domain,
            url: `${routes.servicesRoute}/?credentials=%7B%7D&details=%7B%7&principal=%7B%7D`,
            headers: {
                Authorization: `Bearer ${localStorage.token}`
            },
            data: payload.data
        })
    }

    static async updateService(payload){

        /* ?credentials=%7B%7D&details=%7B%7&principal=%7B%7D */
        return axios({
            method: 'PUT',
            baseURL: domain,
            url: `${routes.servicesRoute}/${payload.id}`,
            headers: {
                Authorization: `Bearer ${localStorage.token}`
            },
            data: payload.data
        })
    }

    static async getRoleTypes(payload){
        const params = getURLParams({...payload});
        return axios({
            method: 'GET',
            baseURL: domain,
            url: `${routes.roleTypeRoute}/${params}`,
            headers: {
                Authorization: `Bearer ${localStorage.token}`
            }
        })
    }

    static async getDiscountTypes(payload){
        const params = getURLParams({...payload});
        return axios({
            method: 'GET',
            baseURL: domain,
            url: `${routes.discountTypeRoute}/${params}`,
            headers: {
                Authorization: `Bearer ${localStorage.token}`
            }
        })
    }

    static async getCoupons(payload){
        const params = getURLParams({...payload});
        return axios({
            method: 'GET',
            baseURL: domain,
            url: `${routes.couponRoute}/${params}`,
            headers: {
                Authorization: `Bearer ${localStorage.token}`
            }
        })
    }

    static async createCoupon(payload){
        return axios({
            method: 'POST',
            baseURL: domain,
            url: `${routes.couponRoute}/?credentials=%7B%7D&details=%7B%7&principal=%7B%7D`,
            headers: {
                Authorization: `Bearer ${localStorage.token}`
            },
            data: payload.data

        })
    }

    static async updateCoupon(payload){
        return axios({
            method: 'PUT',
            baseURL: domain,
            url: `${routes.couponRoute}/${payload.id}/?credentials=%7B%7D&details=%7B%7&principal=%7B%7D`,
            headers: {
                Authorization: `Bearer ${localStorage.token}`
            },
            data: payload.data
        })
    }


    static async getWalletBalance(){
        return axios({
            method: 'GET',
            baseURL: domain,
            url: `${routes.walletBalanceRoute}`,
            headers: {
                Authorization: `Bearer ${localStorage.token}`
            },
        })
    }

    static providusRePush(payload){
        return axios({
            method: 'GET',
            baseURL: domain,
            url: `${routes.providusRepush}/?settlementId=${payload.settlementid}&sessionId=${payload.sessionid}`,
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                Authorization: `Bearer ${localStorage.token}`
            }
        })
    }

    static togglepnadt(payload){
        return axios({
            method: 'POST',
            baseURL: domain,
            url: `/admin/users/${payload.data.id}/toggle-pndat`,
            headers: {
                Authorization: `Bearer ${localStorage.token}`
            },
            data: payload.data
        })
    }

    static addFAQ(payload){
        return axios({
            method: 'POST',
            baseURL: domain,
            url:`${routes.faqRoutes}`,
            headers: {
                Authorization: `Bearer ${localStorage.token}`
            },
            
            data: payload
        })
    }

    static fetchAllFAQs(payload){
        const page = payload.page || 0;
        const size = payload.pageSize || 10;
        
        return axios({
            method: 'GET',
            baseURL: domain,
            url:`${routes.faqRoutes}?page=${page}&size=${size}`,
            headers: {
                Authorization: `Bearer ${localStorage.token}`
            }
        })
    }

    static updateFAQ(payload){
        return axios({
            method: 'PUT',
            baseURL: domain,
            url:`${routes.faqRoutes}/${payload.id}`,
            headers: {
                Authorization: `Bearer ${localStorage.token}`
            },
            
            data: payload
        })
    }

    static deleteFAQ(payload){
        return axios({
            method: 'DELETE',
            baseURL: domain,
            url:`${routes.faqRoutes}/${payload.id}`,
            headers: {
                Authorization: `Bearer ${localStorage.token}`
            }
        })
    }

    
    static fetchfaqCategories(payload){
        const page = payload.page || 0;
        const size = payload.pageSize || 10;
        
        return axios({
            method: 'GET',
            baseURL: domain,
            url:`${routes.faqCategoriesRoutes}?page=${page}&size=${size}`,
            headers: {
                Authorization: `Bearer ${localStorage.token}`
            }
        })
    }

    static addFAQCategory(payload){
        return axios({
            method: 'POST',
            baseURL: domain,
            url:`${routes.faqCategoriesRoutes}`,
            headers: {
                Authorization: `Bearer ${localStorage.token}`
            },
            
            data: payload
        })
    }

    static updateFAQCategory(payload){
        return axios({
            method: 'PUT',
            baseURL: domain,
            url:`${routes.faqCategoriesRoutes}/${payload.id}`,
            headers: {
                Authorization: `Bearer ${localStorage.token}`
            },
            
            data: payload
        })
    }

    static deleteFAQCategory(payload){
        return axios({
            method: 'DELETE',
            baseURL: domain,
            url:`${routes.faqCategoriesRoutes}/${payload.id}`,
            headers: {
                Authorization: `Bearer ${localStorage.token}`
            }
        })
    }

    static fetchPolicies(type){
        return axios({
            method: 'GET',
            baseURL: domain,
            url:`${routes.policiesRoutes}/?type=${type}`,
            headers: {
                Authorization: `Bearer ${localStorage.token}`
            }
        })
    }

    static addPolicy(payload){
        return axios({
            method: 'POST',
            baseURL: domain,
            url:`${routes.policiesRoutes}`,
            headers: {
                Authorization: `Bearer ${localStorage.token}`
            },
            
            data: payload
        })
    }

    static updatePolicy(payload){
        return axios({
            method: 'PUT',
            baseURL: domain,
            url:`${routes.policiesRoutes}/${payload.id}`,
            headers: {
                Authorization: `Bearer ${localStorage.token}`
            },
            
            data: payload
        })
    }

    static fetchTerms(type){
        return axios({
            method: 'GET',
            baseURL: domain,
            url:`${routes.termsRoutes}/?type=${type}`,
            headers: {
                Authorization: `Bearer ${localStorage.token}`
            }
        })
    }

    static addTerms(payload){
        return axios({
            method: 'POST',
            baseURL: domain,
            url:`${routes.termsRoutes}`,
            headers: {
                Authorization: `Bearer ${localStorage.token}`
            },
            
            data: payload
        })
    }

    static editTerms(payload){
        return axios({
            method: 'PUT',
            baseURL: domain,
            url:`${routes.termsRoutes}/${payload.id}`,
            headers: {
                Authorization: `Bearer ${localStorage.token}`
            },
            
            data: payload
        })
    }

    static fetchConfigs(payload){

        const pageNo = payload.page || 0;
        const pageSize = payload.pageSize || 10;

        return axios({
            method: 'GET',
            baseURL: domain,
            url:`${routes.configsRoutes}/?pageNo=${pageNo}&pageSize=${pageSize}`,
            headers: {
                Authorization: `Bearer ${localStorage.token}`
            }
        })
    }


    static updateConfigs(payload){
        return axios({
            method: 'PUT',
            baseURL: domain,
            url:`${routes.configsUpdate}`,
            headers: {
                Authorization: `Bearer ${localStorage.token}`
            },
            
            data: payload
        })
    }

    static fetchDocuments(payload){

        const page = payload.page || 0;
        // const limit = payload.pageSize || 10;

        return axios({
            method: 'GET',
            baseURL: domain,
            url:`${routes.documentsRoutes}/?page=${page}`,
            headers: {
                Authorization: `Bearer ${localStorage.token}`
            }
        })
    }

    static verifyDocument(payload){

        return axios({
            method: 'PUT',
            baseURL: domain,
            url:`${routes.documentsRoutes}/${payload.id}`,
            headers: {
                Authorization: `Bearer ${localStorage.token}`
            },

            data: payload
        })
    }
}
