import { createSlice  } from "@reduxjs/toolkit";
import { Services } from "../../../services";


const documentSlice = createSlice({
    name: 'documentSlice',
    initialState: {
        documentList:  [],
    },

    reducers: {
        addDocumentList: (state, {payload}) => {
            state.documentList = payload;
        },
    }
});


export const { addDocumentList} = documentSlice.actions;


export const fetchAllDocuments = (payload) => dispatcher => {
    return Services.fetchDocuments(payload).then(
           
            response => {
                // console.log(response);
                    dispatcher(addDocumentList({
                        data: response.data,
                      }))
                return Promise.resolve()
            }
    ).catch(error => {
        if(error.response){
            return Promise.reject(error.response.data);
        }
    })
}


export const selectDocument = state => state.documentReducer;
export default documentSlice.reducer;

