import { createSlice  } from "@reduxjs/toolkit";
import { Services } from "../../../services";


const configSlice = createSlice({
    name: 'configSlice',
    initialState: {
        configList:  { data: [], limit: 0, page: 0, total: 0 },
    },

    reducers: {
        addConfigList: (state, {payload}) => {
            state.configList = payload;
        },
    }
});


export const { addConfigList} = configSlice.actions;


export const fetchAllConfigs = (payload) => dispatcher => {
    return Services.fetchConfigs(payload).then(
           
            response => {
                    dispatcher(addConfigList({
                        data: response.data.data.content,
                        total: response.data.data.totalElements,
                        limit: response.data.data.size,
                        page: response.data.data.number,
                      }))
                return Promise.resolve()
            }
    ).catch(error => {
        if(error.response){
            return Promise.reject(error.response.data);
        }
    })
}

export const selectConfig = state => state.configReducer;
export default configSlice.reducer;

